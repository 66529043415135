import React from "react"
import { Link } from "gatsby"

const Button = ({ children, light, link, onClick, style }) => {
  const button = (
    <button
      style={{
        backgroundColor: light ? "white" : "#194056",
        color: light ? "#194056" : "white",
        fontSize: "15px",
        padding: "10px 8px",
        cursor: "pointer",
        whiteSpace: "nowrap",
        ...style,
      }}
      onClick={onClick}
    >
      <span>{children}</span>
    </button>
  )

  if (link) {
    return (
      <a href={link} target="_blank">
        {button}
      </a>
    )
  }

  if (onClick) {
    return button
  }
  return (
    <div>
      <Link to="/#contact-section">{button}</Link>
    </div>
  )
}

export default Button
