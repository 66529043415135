import React from "react"
import "./SectionLayout.css"

const SectionLayout = ({
  children,
  bgColor = "#fff",
  innerMaxWidth,
  style,
}) => (
  <div className="full-width" style={{ backgroundColor: bgColor, ...style }}>
    <div className="full-width__inner" style={{ maxWidth: innerMaxWidth }}>
      {children}
    </div>
  </div>
)

export default SectionLayout
