/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <div>
        <main>{children}</main>
        <footer
          style={{
            padding: "20px",
            backgroundColor: "#fddb4d",
            fontSize: "12px",
            textAlign: "center",
            color: "#686868",
          }}
        >
          © {new Date().getFullYear()} by Alexa Steinbrück | 
          <a href="https://alexasteinbruck.com/imprint.html">Imprint</a> | 
          <a href="https://alexasteinbruck.com/privacy.html">Privacy Policy</a>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
