import { Link } from "gatsby"
import React from "react"
import Button from "./button"
import SectionLayout from "./SectionLayout"

import "./header.css"

const Header = ({}) => (
  <SectionLayout bgColor="#fddb4d" style={{ padding: "60px 0 20px" }}>
    <header className="header">
      <div className="header__grid">
        <div className="header__icon"></div>
        <div className="header__site-title">
          <Link className="header__site-title__name" to="/">
            <h1 style={{ marginBottom: 0 }}>
              ALEXA STEINBRÜCK ~ <br />
              <span style={{ whiteSpace: "nowrap" }}>
                CREATIVE A.I. WORKSHOPS
              </span>
            </h1>
          </Link>

          <p className="header__site-title__description">
            Workshops that demystify Artificial Intelligence & <br /> promote
            informed and creative relationships with technology
          </p>
        </div>
        <div className="header__contact-button">
          <Button>Get in touch</Button>
        </div>
      </div>
      <nav className="header__nav">
        {
          <ul className="header__nav__list">
            <li>
              <Link className="header__nav__item" to="/">
                Home
              </Link>{" "}
            </li>
            <li>
              <Link className="header__nav__item" to="/about">
                About me
              </Link>{" "}
            </li>
            <li className="header__nav__item">Resources</li>
          </ul>
        }
      </nav>
    </header>
  </SectionLayout>
)

export default Header
